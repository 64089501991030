<template>
    <div>
        <div class="livevideo_countdown_box">
            <div>{{ ACTIVITYTYPE[this.item.statusType] }}</div>
            <div v-if="item.statusType == 1 || item.statusType == 2 || item.statusType == 4" class="timeBox">
                <div class="livevideo_countdown_end" style="color:#00B277;border:#00B277 1px solid">
                    {{ info.days }}天</div>
                <div style="color:#00B277"> : </div>
                <div class="livevideo_countdown_end" style="color:#00B277;border:#00B277 1px solid">
                    {{ info.hours }}时</div>
                <div style="color:#00B277"> : </div>
                <div class="livevideo_countdown_end" style="color:#00B277;border:#00B277 1px solid">
                    {{ info.minutes }}分</div>
                <div style="color:#00B277"> : </div>
                <div class="livevideo_countdown_end" style="color:#00B277;border:#00B277 1px solid">
                    {{ info.seconds }}秒</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: ['item'],
    data() {
        return {
            moment,
            ACTIVITYTYPE: {
                1: '距离活动结束：',
                2: '距离报名结束：',
                3: '报名截止',
                4: '距离活动开始：',
                5: '活动已结束'
            },
            info: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            }
        }
    },
    mounted() {
        if (this.item.statusType == 1) {
            setInterval(() => {
                this.countdown(new Date(), this.item.endTime)
            }, 1000);
        } else if (this.item.statusType == 2) {
            let time = new Date(this.item.applyEndTime).getTime()
            setInterval(() => {
                this.countdown(new Date(), time)
            }, 1000);
        } else if (this.item.statusType == 4) {
            setInterval(() => {
                this.countdown(new Date(), this.item.startTime)
            }, 1000);
        }
    },
    methods: {
        countdown(startTime, endTime) {
            var timestamp = moment(new Date()).format('x');
            var subtime = (endTime - timestamp) / 1000;    //计算时间差,并将毫秒转化为秒
            var days = parseInt(subtime / 86400);  //天  24*60*60*1000
            var hours = parseInt(subtime / 3600) - 24 * days;   //小时  60*60  总小时数-过去小时数=现在小时数
            var minutes = parseInt(subtime % 3600 / 60);    //分钟 - (day*24)  以60秒为一整份  取余 剩下秒数 秒数/60就是分钟数
            var seconds = parseInt(subtime % 60);   //以60秒为一整份  取余  剩下秒数
            if (days == 0 && hours == 0 && minute == 0 && second == 0) {
                this.$emit('refreshList')
            }
            let info = {
                days,
                hours,
                minutes,
                seconds,
            }
            this.info = info
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/pixel/pixel";

.timeBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.homecontent_livevideo_bigBox {
    width: 100%;
}

.homecontent_BIO_xian {
    width: 100%;
    height: @10px;
    background: #F8F8F8;
    margin-top: @30px;
}

.homecontent_livevideo_box {
    width: @700px;
    margin-left: @25px;
}

.homecontent_livevideo_titlebox {
    width: 100%;
    position: relative;
}

.homecontent_livevideo_title {
    font-size: @34px;
    // width: @136px;
    height: @48px;
    font-weight: bold;
    margin-top: @17px;
}

.homecontent_livevideo_morehot {
    // width: @104px;
    height: @36px;
    font-size: @26px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #777777;
}

.homecontent_livevideo_contentbox {
    width: 100%;
    position: relative;
    padding-bottom: @20px;
    margin-top: @10px;
    border-bottom: 1px solid #F8F8F8;
}

.homecontent_livevideo_contentbox:last-child {
    border-bottom: 1px solid #FFF;
}

.homecontent_livevideo_label {
    position: absolute;
    top: @40px;
    left: @20px;
    width: @110px;
    height: @44px;
    font-size: @24px;
    background: #EF882C;
    border-radius: @6px;
    color: #FFFFFF;
    text-align: center;
    line-height: @44px;
}

.livevideo_content_img {
    width: @700px;
    height: @290px;
    border-radius: @20px;
    margin-top: @20px;
    object-fit: cover;
}

.livevideo_content_title {
    margin-top: @10px;
    font-size: @34px;
}

.livevideo_countdown_box {
    width: 100%;
    height: @40px;
    display: flex;
    align-items: center;
    font-size: @24px;
    margin-top: @20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.livevideo_countdown_end {
    width: @70px;
    height: @36px;
    color: #EF882C;
    border: #EF882C 1px solid;
    border-radius: @2px;
    text-align: center;
    margin-left: @20px;
    margin-right: @20px;
    line-height: @36px;
}
</style>