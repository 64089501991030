import { render, staticRenderFns } from "./myhotevents.vue?vue&type=template&id=4d5a35bd&scoped=true"
import script from "./myhotevents.vue?vue&type=script&lang=js"
export * from "./myhotevents.vue?vue&type=script&lang=js"
import style0 from "./myhotevents.vue?vue&type=style&index=0&id=4d5a35bd&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d5a35bd",
  null
  
)

export default component.exports