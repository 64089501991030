import { render, staticRenderFns } from "./Recruitment.vue?vue&type=template&id=fb0cda52&scoped=true"
import script from "./Recruitment.vue?vue&type=script&lang=js"
export * from "./Recruitment.vue?vue&type=script&lang=js"
import style0 from "./Recruitment.vue?vue&type=style&index=0&id=fb0cda52&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb0cda52",
  null
  
)

export default component.exports