<template>
    <div class="homecontent_livevideo_bigBox">
        <div>
            <div class="homecontent_livevideo_box">
                <div class="homecontent_livevideo_titlebox">
                    <div class="homecontent_livevideo_title">热门活动</div>
                    <div class="homecontent_livevideo_morehot" @click="goMore">更多活动</div>
                </div>
                <div class="homecontent_livevideo_contentbox" v-for="(item, index) in livevideoContentList" :key="index">
                    <!-- 未开始倒计时 -->
                    <div @click='gohotevents(0, item)'>
                        <div class="homecontent_livevideo_label" v-if="item.statusType == 4">未开始
                        </div>
                        <div class="homecontent_livevideo_label" v-if="item.statusType == 1" style="background:#00B277">进行中
                        </div>
                        <img class="livevideo_content_img" :src="item.pictures" alt="">
                        <div class="livevideo_content_title">{{ item.title }}</div>
                        <timeBoxVue :item="item" @refreshList='postindexActivities' />
                    </div>
                    <!-- <wx-open-launch-weapp
                        v-if="item.type===4"
                        id="launch-btn"
                        appid="wx7360800558d951b0"
                        path="pages/index/index.html"
                    >
                    <script type="text/wxtag-template">
                        <div class=“btn” style='width:100%;height:90%;position:absolute; top:0;left:0; opacity: 0;'>打开测试小程序</div>
                    </script>
                    </wx-open-launch-weapp> -->
                </div>
            </div>
            <div class="homecontent_BIO_xian"></div>
        </div>
        <!--  弹框  -->
        <van-popup v-model="expecttype" round closeable @click-close-icon="expecttype = false">
            <div class="wrapper">
                <div class="block">
                    <img src="../../assets/home/jbz.jpg" alt="">
                    <p class="tips">长按图片识别</p>
                </div>
            </div>
        </van-popup>
    </div>
</template>


<script>
import { postindexActivitiesAPI } from '../../api/home'
import moment from 'moment'
import timeBoxVue from './compoents/timeBox.vue'
import { wxSetting } from "@/mixins/wx/index";
export default {
    name: 'hotevents',
    //热门活动
    components: {
        timeBoxVue
    },
    data() {
        return {
            moment,
            livevideoContentList: [],
            seTtimeouts: '',
            hoteventsContentListtype: false,
            expecttype: false
        }
    },
    created() {
        this.postindexActivities()
        wxSetting()
        // wx.ready(function () {
        // })
    },
    methods: {
        goMore() {
            this.$router.push("/hoteventsmore")
        },
        gohotevents(e, item) {
            if (item.type === 4) {
                this.expecttype = true
            } else if (item.type === 2) {
                // 去
                let serviceID = item.relatedServiceID
                this.$router.push(`/vote/group/item/${serviceID}`)
            } else if (item.type === 7) {
                // 去
                let serviceID = item.relatedServiceID
                this.$router.push(`/cardCollecting/${serviceID}`)
            } else if (item.type === 5) {
                // 去
                let serviceID = item.relatedServiceID
                this.$router.push(`/lottery/${serviceID}`)
            } else if (item.type === 6) {
                // 去
                let serviceID = item.relatedServiceID
                this.$router.push(`/midAutumn/${serviceID}`)
            } else {
                // 去
                let serviceID = this.livevideoContentList[e].serviceID
                this.$router.push(`/puzzle/${serviceID}`)
            }

        },
        postindexActivities() {
            let params = {
                page: 1,
                pageSize: 3,
                kv: {}
            }
            postindexActivitiesAPI(params)
                .then(res => {
                    console.log(res.data.data.list, '-------res')
                    let list = res.data.data.list || []
                    this.livevideoContentList = list.map(
                        (item, index) => {
                            item.startTime = moment(item.startTime).format('x')
                            item.endTime = moment(item.endTime).format('x')
                            return item
                        }
                    )
                    console.log(this.livevideoContentList)
                })
        },
        settimeoutfuntion() {
            // //视频未开始倒计时
            let endtime = 0
            this.livevideoContentList.map(v => {
                if (v.difftime > 0) {
                    v.day = parseInt(v.difftime / (60 * 60 * 24))
                    v.hours = parseInt(v.difftime / (60 * 60) % 24)
                    v.minutes = parseInt(v.difftime / 60 % 60)
                    v.seconds = parseInt(v.difftime % 60)
                    v.difftime = v.difftime - 1
                    this.$forceUpdate()
                } else {
                    endtime = 1
                }
            })
            if (this.seTtimeouts) clearTimeout(this.seTtimeouts)
            this.seTtimeouts = setTimeout(() => {
                if (endtime == 0) {
                    this.settimeoutfuntion()
                } else {
                    this.postindexActivities()
                }
            }, 1000);

        }
    },
    beforeDestroy() {
        clearTimeout(this.seTtimeouts)
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.homecontent_livevideo_bigBox {
    width: 100%;
}

.homecontent_BIO_xian {
    width: 100%;
    height: @10px;
    background: #F8F8F8;
    margin-top: @30px;
}

.homecontent_livevideo_box {
    width: @700px;
    margin-left: @25px;
}

.homecontent_livevideo_titlebox {
    width: 100%;
    position: relative;
}

.homecontent_livevideo_title {
    font-size: @34px;
    // width: @136px;
    height: @48px;
    font-weight: bold;
    margin-top: @17px;
}

.homecontent_livevideo_morehot {
    // width: @104px;
    height: @36px;
    font-size: @26px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #777777;
}

.homecontent_livevideo_contentbox {
    width: 100%;
    position: relative;
    padding-bottom: @20px;
    margin-top: @10px;
    border-bottom: 1px solid #F8F8F8;
    height: @486px;
}

.homecontent_livevideo_contentbox:last-child {
    border-bottom: 1px solid #FFF;
}

.homecontent_livevideo_label {
    position: absolute;
    top: @40px;
    left: @20px;
    width: @110px;
    height: @44px;
    font-size: @24px;
    background: #EF882C;
    border-radius: @6px;
    color: #FFFFFF;
    text-align: center;
    line-height: @44px;
}

.livevideo_content_img {
    width: @700px;
    height: @290px;
    border-radius: @20px;
    margin-top: @20px;
    object-fit: cover;
}

.livevideo_content_title {
    margin-top: @10px;
    font-size: @34px;
}

.livevideo_countdown_box {
    width: 100%;
    height: @40px;
    display: flex;
    align-items: center;
    font-size: @24px;
    margin-top: @20px;
}

.livevideo_countdown_end {
    width: @70px;
    height: @36px;
    color: #EF882C;
    border: #EF882C 1px solid;
    border-radius: @2px;
    text-align: center;
    margin-left: @20px;
    margin-right: @20px;
    line-height: @36px;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: @30px @40px;

    .block {
        width: @500px;
        height: auto;
        background-color: #fff;

        .tips {
            font-size: @26px;
            color: #666666;
            text-align: center;
            margin-top: @30px;
        }
    }
}

#launch-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    left: 0;
    top: 0;
    // background-color: blue;
}
</style>