<template>
  <div id="app" class="Img">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view/>
  </div>
</template>

<!-- <script>

export default {
  name: 'app',
  components: {
  },
  data() {
    return {
      showIndex: false,
      ifShowTab: false,
      title: '',
      show: false,
      imgs: false
    }
  },
  computed: {
  },
  watch: {
    $route: {
      deep: true,
      handler(to, from) {
        console.log(to.path.includes('/midAutumnAnswer'),'sjhhjgjhgjhghj')
        if (to.path.includes('/midAutumnAnswer')) {
          this.imgs = true
        } else {
          this.imgs = false
        }
        this.ifShowTab = false
        this.title = ''
        for (let matched of to.matched) {
          if (ROUTE_PATH.find(el => matched.path.includes(el.path))) {
            this.ifShowTab = true
            this.title = to.meta.title
          }
        }
      }
    }
  },
  mounted() {
  },
  created() {
    
  },
  methods: {}
}
</script> -->
<script>

  window.onload = function() {
   document.addEventListener('touchstart', function(event) {
   if (event.touches.length > 1) {
    event.preventDefault()
   }
   })
   document.addEventListener('gesturestart', function(event) {
   event.preventDefault()
   })
  }

</script>
<style lang="less">
@import "~vux/src/styles/1px.less";
//  vux的1px解决方案
@import "./assets/css/minix/minix.less";
// 其他样式
@import "./assets/css/vux/formStyle.less";
// 表单样式
@import "./assets/css/vux/toast.less";
// vux样式
@import "./assets/css/pixel/pixel"; // 长度单位
@import "./assets/css/common.css";
#app {
  width: 100%;
  min-height: 100%;
}
html, body{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: @32px;
  //overflow-y: scroll; // 不能加这个，不然会影响mscroll的scroll事件监听

  // 隐藏滚动条
  //&::-webkit-scrollbar {
  //  width: 0;
  //  height: 0;
  //}
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.no-data-class {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}
</style>
