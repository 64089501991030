<template>
  <!-- 分享 -->
  <transition name="fade">
    <div class="shareMask" @click="show = false" @touchmove.prevent v-if="show">
      <i class="iconfont icon-jiantou"></i>
      <p>点击此处“• • •”，分享给好友吧…</p>
      <button o-fb @click="show = false">知道啦</button>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      show: false
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../../../assets/css/pixel/pixel.less';
  @import '../../../assets/css/minix/minix.less';
  .shareMask{
    .fixed;
    background:rgba(0,0,0,0.65);
    z-index: 99999;
    .icon-jiantou{
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
      font-size: @250px;
    }
    p{
      font-size:@30px;
      color:#ffffff;
      line-height:@30px;
      position: absolute;
      right: 50%;
      transform: translate(50%);
      top: @272px;
      text-align: center;
      width: 100%;
    }
    button{
      border:1px solid #ffffff;
      border-radius:@8px;
      width:@188px;
      height:@72px;
      background: transparent;
      font-size:@32px;
      color:#ffffff;
      position: absolute;
      right: 50%;
      transform: translate(50%);
      top: @336px;
    }
  }
</style>
