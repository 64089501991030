<template>
    <div class="headlinemore_bigBox">
        <div class="headlinemore_box">
            <div class="headlinemore_titlebox">
                <div class="headlinemore_input_bigBox">
                    <input @keyup.enter="clickSearch" @focus="focusinput" v-model="inputSearchValue"
                        class="headlinemore_input_box" placeholder="输入标题搜索" type="search">
                    <div>
                        <SvgIcon class="headlinemore_input_svg" iconClass="icon-sousuo-10"></SvgIcon>
                    </div>
                    <div v-if="inputSearchValue != ''" @click="clearSearch" class="hoteventsmore_input_X">
                        <svg t="1665641559757" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="22455" width="16" height="16">
                            <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#AAAAAA"
                                p-id="22456"></path>
                            <path
                                d="M583.68 512l136.533333-136.533333c20.48-20.48 20.48-51.2 0-71.68s-51.2-20.48-71.68 0l-136.533333 136.533333-136.533333-136.533333c-20.48-20.48-51.2-20.48-71.68 0s-20.48 51.2 0 71.68l136.533333 136.533333-136.533333 136.533333c-20.48 20.48-20.48 51.2 0 71.68s51.2 20.48 71.68 0l136.533333-136.533333 136.533333 136.533333c20.48 20.48 51.2 20.48 71.68 0s20.48-51.2 0-71.68l-136.533333-136.533333z"
                                fill="#FFFFFF" p-id="22457"></path>
                        </svg>
                    </div>
                    <div v-if="searchtype" @click="clickSearch" class="headlinemore_input_search">搜索</div>
                </div>
            </div>
            <!-- tab -->
            <div>
                <van-tabs swipe-threshold="1" v-model="active" @click="changeTabs">
                    <template class="headlinemore_van_tab">
                        <van-tab :title="item.name" :name="item.headlinesTypeID" v-for="(item,index) in SwitchList" :key="index"></van-tab>
                    </template>
                </van-tabs>
            </div>
            <div class="headlinemore_content_bigboxs">
              <m-scroll ref="mscroll" v-model="pages" :getData="postheadlineslist" emptyText="暂无数据">
                <div @click="golink(index)" :class="index===0?'firstbox':'headlinemore_content_bigbox'"
                    v-for="(item,index) in biologymoreList" :key="index">
                    <div class="headlinemore_content_box">
                        <div class="headlinemore_contentbox_imgs">
                            <div v-if="item.type == 1" class="my_swipe_videoplayerbox"></div>
                            <div v-if="item.type == 1" class="">
                                <SvgIcon class="my_swipe_videoSvg" iconClass="icon-play"></SvgIcon>
                            </div>
                            <img class="headlinemore_contentbox_img" :src="item.url" alt="">
                        </div>
                        <div class="headlinemore_contentbox_title">
                            {{item.title}}
                        </div>
                        <div class="headlinemore_contentbox_time">
                            <div v-if="item.state == 1" class="headlinemore_contentbox_top">置顶</div>
                            <div class="headlinemore_contentbox_look">{{item.headlinesTypeName}}</div>
                            <span class="headlinemore_contentbox_titles">{{item.time}}</span>
                            <span class="headlinemore_contentbox_titles">{{item.browseTotal}}</span>
                            <SvgIcon class="headlinemore_contentbox_svg"
                                iconClass="icon-liulanliang"></SvgIcon>
                        </div>
                    </div>
                    <div class="headlinemore_contentbox_xian"></div>
                </div>
              </m-scroll>
            </div>
            <!-- 视频 -->
            <div v-if="videoplay_Box" class="videoplay_Box">
                <div @click="closeVideoplayBox">
                    <SvgIcon class="videoplay_closeSvg" iconClass="icon-guanbi"></SvgIcon>
                </div>
                <video controls autoplay webkit-playsinlin="true" playsinline="true" x5-video-player-type="h5"
                    x5-video-player-fullscreen="true" :src="videourl">
                </video>
            </div>
        </div>
    </div>
</template>

<script>
import { postheadlinesTypeAPI, postheadlineslistAPI,postCommonAddClick } from '../../api/home'
export default {
    name: 'biologyMore',
    //更多生物科普
    components: {

    },
    data() {
        return {
            videourl: "",
            videoplay_Box: false,
            active: 0,//tab标签页
            searchtype: false,
            inputSearchValue: "",
            biologymoreList: [],
            SwitchList: [{
              name: "全部",
              headlinesTypeID: 0
            }],
            BIOcontentBoxList: [
                {
                    url: require("../../assets/home/headNews.png"),
                    title: "为了建造洁净空间，他们用“显微镜”找尘埃粒111",
                    browseTotal: 2666,
                    time: "2022-08-22",
                    type: 1,
                },
            ],
          pages: {
            page: 1,
            pageSize: 10,
            kv: {}
          }
        }
    },
    watch: {
    },
    created() {
        this.postheadlinesType()
    },
    methods: {
      focusinput() {
          //获取焦点
          this.searchtype = true
      },
      clearSearch() {
          // 取消
        this.active = 0
        this.inputSearchValue = ''
        this.pages.page = 1
        this.searchtype = false
        this.postheadlineslist()
      },
      clickSearch() {
        this.active = 0
        this.pages.page = 1
         this.postheadlineslist()
      },
      // 切换tab
      changeTabs(name, title) {
        this.active = name
        this.pages.page = 1
        this.postheadlineslist()
      },
        postheadlinesType() {
            // 资讯头条 分类
            postheadlinesTypeAPI().then(res => {
              let list = res.data.data || []
              this.SwitchList = [...this.SwitchList, ...list]
              this.active = this.SwitchList[0].headlinesTypeID
              this.postheadlineslist()
            })
        },
        // 资讯头条 列表
        postheadlineslist() {
          if (this.pages.page === 1) this.biologymoreList = []
            if (this.active > 0) {//全部kv传空
              this.pages.kv = {
                title: this.inputSearchValue,
                headlinesTypeID: this.active,
              }
            } else {
              // 全部并且搜搜
              this.pages.kv = {
                title: this.inputSearchValue,
              }
            }
            postheadlineslistAPI(this.pages).then(res => {
              let list = res.data.data.list || []
              list.map(v => {
                  v.time = v.createTime.slice(0, 16)
                  let newpicture = JSON.parse(v.picture)
                  v.url = newpicture[0].url
                  if (v.video) {
                      let videourl = JSON.parse(v.video)
                      v.videos = videourl.url
                  }
              })
              this.biologymoreList = [...this.biologymoreList, ...list]
              this.$nextTick(_ => {
                this.$refs.mscroll.endSuccess(res.data.data)
              })
            })
        },
        closeVideoplayBox() {
            // 关闭播放器
            this.videoplay_Box = false
        },
        golink(index) {
            //计算浏览量
            postCommonAddClick('headlines', this.biologymoreList[index].headlinesID)
                .then(res => {
                })
            // 去外链
            if (this.biologymoreList[index].source == 2) {
                this.$router.push({
                    name: 'article',
                    query: {
                        serviceID: this.biologymoreList[index].serviceID,
                        from: "资讯头条"
                    }
                })
                return
            }
            if (this.biologymoreList[index].type == 1) {
                // 打开播放器
                this.videourl = this.biologymoreList[index].videos
                this.videoplay_Box = true
            } else {
                if (this.biologymoreList[index].link) window.location.href = this.biologymoreList[index].link
            }
        }
    },

}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.headlinemore_bigBox {
  width: 100%;
  height: 100%;
}

.headlinemore_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
}

/deep/ .van-tabs__line {
    background: #EF882C;
}

/deep/.van-tab {
    font-size: @30px;
}

/deep/.van-tab--active {
    font-weight: bold;
    font-size: @34px !important;
}

.headlinemore_titlebox {
    width: @690px;
    margin-left: @30px;
    position: relative;
}


.headlinemore_input_bigBox {
    margin-top: @30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: @20px;
}

.headlinemore_input_box {
    width: 100%;
    height: @70px;
    background: #F6F6F6;
    border: 1px solid #FFF;
    font-size: @30px;
    border-radius: @40px;
    /* border: 0 none; */
    /* outline: none; */
    padding-left: @60px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}

input::-webkit-input-placeholder {
    /* 输入框提示文字颜色 */
    font-size: @30px;
}

.headlinemore_input_svg {
    width: @30px;
    height: @30px;
    position: absolute;
    left: @20px;
    top: @20px;
}

.hoteventsmore_input_X {
    width: @20px;
    height: @30px;
    position: absolute;
    right: @115px;
    top: @20px;

}

.headlinemore_vantabs {
    overflow-x: scroll;
}

.headlinemore_input_search {
    width: @70px;
    margin-left: @10px;
    /* display: flex;
    align-items: center; */
    text-align: center;
    line-height: @70px;
    font-size: @28px;
    color: #00B277;
}



.navber_bottom_box {
    width: 100%;
    height: 7vh;
    display: flex;
    position: fixed;
    bottom: 0;
}

.navber_bottom_smallBox {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: @20px;
}

.navber_bottom_svg {
    width: @40px;
    height: @40px;
    margin-left: 50%;
    transform: translate(-50%);
}


.headlinemore_content_bigboxs{
    width: 100%;
    flex: 1;
    overflow-y: auto;
}

.headlinemore_content_bigbox {
    width: 100%;
    background: #FFF;
    margin-top: @20px;
    border-radius: @20px;
}

.firstbox {
    width: 100%;
    background: -webkit-linear-gradient(top, rgba(239, 136, 44, 0.1), rgba(239, 136, 44, 0));
    border-radius: @20px;
    margin-top: @20px;
    padding-top: @20px;
}

.headlinemore_content_box {
    width: @690px;
    margin-left: @30px;

}

.headlinemore_contentbox_imgs{
    width: 100%;
    height: @290px;
    border-radius: @20px;
    position: relative;
}
.headlinemore_contentbox_img {
    width: 100%;
    height: @290px;
    border-radius: @20px;
    object-fit: cover;
}

.headlinemore_contentbox_title {
    font-size: @34px;
    margin-top: @20px;
}

.headlinemore_contentbox_time {
    height: @44px;
    margin-top: @20px;
    font-size: @26px;
    color: #999999;
    height: @36px;
    line-height: @36px;
    display: flex;
    align-items: center;
}

.headlinemore_contentbox_top {
    width: @80px;
    height: @40px;
    color: #EF882C;
    background: rgba(239, 136, 44, 0.08);
    border-radius: @22px;
    text-align: center;
    line-height: @35px;
    padding: @5px;
}

.headlinemore_contentbox_look {
    // width: @130px;
    height: @40px;
    color: #666666;
    background: #F8F8F8;
    border-radius: @22px;
    text-align: center;
    line-height: @35px;
    padding: @5px;

}

.headlinemore_contentbox_titles {
    height: @40px;
    text-align: center;
    line-height: @35px;
    padding: @5px;
    margin-left: @10px;
}

.headlinemore_contentbox_svg {
    width: @26px;
    height: @22px;
    margin-left: @3px;
}

.headlinemore_contentbox_xian {
    margin-top: @30px;
    width: 100%;
    height: @10px;
    background: #F8F8F8;
}

.videoplay_Box {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background: #000
}

.videoplay_closeSvg {
    width: @40px;
    height: @40px;
    position: fixed;
    top: @40px;
    right: @40px;
    z-index: 10;

}

.my_swipe_videoplayerbox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: @10px;
}
.my_swipe_videoSvg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: @60px;
    height: @60px;

}
</style>
