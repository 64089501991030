import Vue from 'vue'
import Share from './share.vue'

const ShareBox = Vue.extend(Share)

Share.install = function (data) {
  let instance = new ShareBox({
    data
  }).$mount()
  if (!document.querySelectorAll(`.shareMask`).length) {
    document.body.appendChild(instance.$el)
  }
  Vue.nextTick(() => {
    instance.show = true
  })
}

export default Share
