<template>
    <div class="headine_bigBox" ref="main">
        <div class="headine_box">
            <div class="headine_top_box">
                <SvgIcon class="headine_box_svg" iconClass="icon-a-zu171"></SvgIcon>
            </div>
            <div @click="golink(index)" ref="content" class="headine_content_box"
                v-for="(item,index) in headlineNewsList" :key="index">
                <div class="headine_content_leftbox">
                    <div class="headine_content_lefttopbox">
                        {{item.title}}
                    </div>
                    <div class="headine_content_leftbottombox">
                        <div v-if="item.state == 1" class="content_leftbottombox_topping">置顶</div>
                        <div class="content_leftbottombox_observation">{{item.headlinesTypeName}}</div>
                        <div class="content_leftbottombox_time">{{item.times}}</div>
                    </div>
                </div>
                <div class="headine_content_rightboxs">
                    <div v-if="item.type == 1" class="my_swipe_videoplayerbox"></div>
                    <div v-if="item.type == 1" class="">
                        <SvgIcon class="my_swipe_videoSvg" iconClass="icon-play"></SvgIcon>
                    </div>
                    <img class="headine_content_rightbox" :src="item.urls" alt="">
                </div>
            </div>
            <div @click="goMore" class="headine_content_moreNews">更多资讯</div>
            <div style="width:100%;height:0.2rem"></div>
        </div>
    </div>
</template>

<script>
import { postheadlinesAPI,postCommonAddClick } from '../../api/home'
export default {
    name: 'headline',
    // 资讯头条
    components: {

    },
    data() {
        return {
            newsNumber: 1,
            headlineNewsList: []
        }
    },
    created() {
        this.postheadlines()
    },
    methods: {
        goMore() {
            this.$router.push("/headlinemore")
        },
        postheadlines() {
            postheadlinesAPI()
                .then(res => {
                    let list = res.data.data || []
                    list.map(v => {
                        let oldtime = new Date(v.createTime).getTime()
                        let newtime = new Date().getTime()
                        let difftime = newtime - oldtime
                        let diffdays = parseInt(difftime / (1000 * 3600 * 24)) //差值 天数
                        v['times'] = diffdays + "天前"
                        if (diffdays == 0) {
                            // 如果不到一天
                            let diffhours = parseInt(difftime / (1000 * 3600)) //差值 小时
                            v['times'] = diffhours + "小时前"
                            if (diffhours == 0) {
                                // 如果不到一小时
                                let diffminutes = parseInt(difftime / (1000 * 60)) //差值 分钟
                                v['times'] = diffminutes + "分钟前"
                            }
                        }
                        if(diffdays > 7){
                            v.times = v.createTime.slice(0, 16)
                        }
                        let newpicture = JSON.parse(v.picture)
                        v.urls = newpicture[0].url
                        if (v.video) v.videos = JSON.parse(v.video) //videos.url
                    })
                    this.headlineNewsList = list
                })
        },
        golink(index) {
             //计算浏览量
             postCommonAddClick('headlines', this.headlineNewsList[index].headlinesID).then(res => {})
            // 去外链
            if (this.headlineNewsList[index].source == 2) {
                this.$router.push({
                    name: 'article',
                    query: {
                        serviceID: this.headlineNewsList[index].serviceID,
                        from: "资讯头条"
                    }
                })
                return
            }
            if (this.headlineNewsList[index].video) {
                this.$emit("videoUrl", this.headlineNewsList[index].videos.url)
            } else {
                if (this.headlineNewsList[index].link) window.location.href = this.headlineNewsList[index].link
            }

        }
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.headine_bigBox {
    width: 100%;
}

.headine_box {
    width: @700px;
    margin-left: @25px;
}

.headine_top_box {
    width: @258px;
    height: @36px;
    color: #00B277;
    margin-left: 50%;
    transform: translate(-50%);
    margin-top: @37px;
    margin-bottom: @40px;
}

.headine_box_svg {
    width: @258px;
    height: @36px;
}

.headine_content_box {
    margin-top: @25px;
    width: 100%;
    height: @180px;
    border-bottom: @1px solid #F8F8F8;
    display: flex;
    justify-content: space-between;
}

.headine_content_leftbox {
    width: @448px;
    height: @150px;
}

.headine_content_lefttopbox {
    width: 100%;
    height: @80px;
    font-size: @34px;
    overflow: hidden;
    // text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*只显示3行，如果不设置这个属性，会出现文本被拦腰截断的现象*/
    -webkit-box-orient: vertical;
}

.headine_content_leftbottombox {
    width: 100%;
    height: 100%;
    margin-top: @22px;
    display: flex;
}

.content_leftbottombox_topping {
    width: @80px;
    height: @40px;
    background: rgba(239, 136, 44, 0.08);
    border-radius: @22px;
    color: rgba(239, 136, 44, 1);
    text-align: center;
    font-size: @24px;
    line-height: @35px;
    padding: @5px;
    margin-right: @10px;
}

.content_leftbottombox_observation {
    width: @130px;
    height: @40px;
    background: #F8F8F8;
    border-radius: @22px;
    color: #666666;
    text-align: center;
    font-size: @24px;
    line-height: @35px;
    padding: @5px;

}

.content_leftbottombox_time {
    margin-left: @30px;
    height: @40px;
    line-height: @35px;
    padding: @5px;
    font-size: @24px;
    color: #888888;

}

.headine_content_rightboxs{
    width: @200px;
    height: @150px;
    border-radius: @6px;
    position: relative;
}
.headine_content_rightbox {
    width: @200px;
    height: @150px;
    border-radius: @6px;
    object-fit: cover;
}

.headine_content_moreNews {
    width: @220px;
    height: @60px;
    background: #EBF9F4;
    color: #00B277;
    font-size: @28px;
    border-radius: @44px;
    text-align: center;
    line-height: @60px;
    margin: auto;
    margin-top: @40px;
}
.my_swipe_videoplayerbox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: @10px;
}

.my_swipe_videoSvg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: @60px;
    height: @60px;

}
</style>
