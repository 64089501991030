import Vue from 'vue'
import App from './App.vue'
import './assets/iconfont/iconfont.css'
import './assets/css/reset/reset.css' // 重置css
import router from './router'
import axios from 'axios'
import store from './store'
import SvgIcon from '@/components/IconFont/SvgIcon.vue'
import iconfont from './components/showInfo/iconfont-svg/index'
import Vant from 'vant';
import './mixins/fiexible' // 实现移动端自适应
import 'vant/lib/index.css';
import 'vant/lib/index.less';
import 'swiper/dist/css/swiper.css' // swiper默认样式
import { ConfirmPlugin, DatetimePlugin, LoadingPlugin, ToastPlugin, TransferDom, WechatPlugin } from 'vux' // 通过this调用vux的Datetime组件
import {
  swiper,
  swiperSlide
} from 'vue-awesome-swiper' // 引入swiper
import './mixins/fiexible' // 实现移动端自适应
import VueCookie from 'vue-cookie'
import BaiduMap from 'vue-baidu-map'
import Config from '@/config/index'
import MScroll from '@/components/mescroll/MScroll' // 滑动组件Mescroll
import Share from './components/dialog/share'
// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// Vue.use(vConsole)
Vue.prototype.$share = Share.install // 分享
Vue.config.ignoredElements = ['wx-open-launch-weapp']
import {
  getAuthUrlAPI,
  wxLogin,
} from './api/log'

Vue.config.productionTip = false
let env = process.env.NODE_ENV
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  console.log('asaasa',to)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  getWechatCode()

  function getWechatCode() {
    let url = window.location.href
    let code = getUrlCode().code; //是否存在code
    let firstLogin = localStorage.getItem('firstLogin')
    console.log('aaaaa', code)
    if (code == null || code === '') { // 如果没有code，则去请求
      console.log(env)
      if (env !== 'development') {
        let data = {
          serviceCode: "bio",
          url: url
        }
        getAuthUrlAPI(data)
          .then(res => {
            localStorage.setItem('firstLogins', 2)
            location.replace(res.data.data) // 重定向
          })
      } else {
        next()
      }
    } else {
      if (Number(firstLogin) === 1) {
        if (code) {
          // 微信登录
          wxLogin(code, "bio")
            .then(res => {
              VueCookie.set('JSESSIONID', res.data.data.serviceID)
              VueCookie.set('serviceCode', "bio")
              localStorage.setItem('firstLogin', 2)
              next()
            })
        } else {
          let data = {
            serviceCode: "bio",
            url: url
          }
          getAuthUrlAPI(data)
            .then(res => {
              VueCookie.delete('JSESSIONID'); //重新请求code就要重新登录 清除cookies
              location.replace(res.data.data) // 重定向
            })
        }
      } else {
        // 第二次登录直接进
        next()
      }
    }
  }

  function getUrlCode() {
    // 截取url中的code方法
    var urls = location.search;
    var theRequest = new Object();
    if (urls.indexOf("?") != -1) {
      var str = urls.substr(1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
    }
    return theRequest;
  }
})
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '0GL5hLoDMUEk7vTuLsVtzTFSklUGOGt6'
})
import '@/utils/tool'
import '@/utils/index'
import { Toast, Dialog, ImagePreview } from 'vant';

Vue.use(Toast);
Vue.use(Dialog);
Vue.use(VueCookie)
Vue.use(Vant);
Vue.prototype.$config = Config
Vue.prototype.$imagePreview = ImagePreview
Vue.component(SvgIcon.name, SvgIcon)
Vue.component('swiper', swiper)
Vue.component('swiperSlide', swiperSlide)
Vue.component('iconfont', iconfont) // 彩色SVg
Vue.component('MScroll', MScroll)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
