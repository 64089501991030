import fetch from '@/api/fetch'

import request from "@/utils/request";

export  function getActivityApi(serviceID){
  return request({
    url:`/wxh5/chip/rule/detail/${serviceID}`,
    method:'get'
  })
}

export  function getMyLastChipNoApi(id){
  return request({
    url:`/wxh5/chip/rule/myChipList/${id}`,
    method:'get'
  })
}

export  function computedChipApi(id){
  return request({
    url:`/wxh5/chip/rule/compound/${id}`,
    method:'post'
  })
}

export  function winListChipApi(data){
  return request({
    url:`/wxh5/chip/rule/winList`,
    method:'post',
    data
  })
}

export  function getMyChipListApi(data){
  return request({
    url:`/wxh5/chip/rule/myNameList`,
    method:'post',
    data
  })
}
