import request from '@/utils/request'
// 人才服务首页 - 显示6条
export function postRecruitment(){
    return request({
        url:"wxh5/recruitment/index",
        method:'post',
    })
}

// 人才服务 人才公寓 列表
export function postTalentsApartmentlist(data){
    return request({
        url:"wxh5/talentsApartment/list",
        method:'post',
        data:data
    })
}
// 人才服务 人才招聘 列表
export function postrecruitmentlist(data){
    return request({
        url:"wxh5/recruitment/list",
        method:'post',
        data:data
    })
}

// 防疫服务 首页 一条
export function getnoticeIndex(){
    return request({
        url:"wxh5/notice/index",
        method:'get',
    })
}
// 防疫服务 列表
export function getnoticeList(data){
  return request({
    url: "/wxh5/notice/list",
    method:'post',
    data
  })
}

// 防疫服务 首页 防疫政策 4条
export function getepidemicPreventionPolicy(){
    return request({
        url:"wxh5/epidemicPreventionPolicy/index",
        method:'get',
    })
}

// 防疫服务 列表
export function getEpidemicList(data){
  return request({
    url: "wxh5/epidemicPreventionPolicy/list",
    method:'post',
    data
  })
}

// 人才招聘 详情
export function recruDetail(serviceID){
  return request({
    url: `/wxh5/recruitment/details/${serviceID}`,
    method: 'get',
  })
}

// 我的投递
export function myDeliveryList(data){
  return request({
    url: `/wxh5/resume/apply/myList`,
    method: 'post',
    data
  })
}
