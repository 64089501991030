<template>
    <div class="hoteventsmore_bigBox">
        <div class="hoteventsmore_box">
            <!-- <div class="hoteventsmore_titlebox">
                <div class="hoteventsmore_input_bigBox">
                    <input @focus="focusinput" v-model="inputSearchValue" class="hoteventsmore_input_box"
                        placeholder="输入标题" type="text">
                    <div @click="postindexActivitieslist">
                        <SvgIcon class="hoteventsmore_input_svg" iconClass="icon-sousuo-10"></SvgIcon>
                    </div>
                    <div v-if="searchtype" @click="clickSearch" class="hoteventsmore_input_search">取消</div>
                </div>
            </div> -->
            <div  @click="gohotevents(index)" class="hoteventsmore_content_Bigbox" :class="index===0?'firstbox':'' "
                v-for="(item,index) in hoteventsmoreContentList" :key="index">
                <div class="hoteventsmore_content_box">
                    <!-- 已开始倒计时 -->
                    <div v-if="item.type">
                        <div class="hoteventsmore_label">活动中</div>
                        <img class="hoteventsmore_content_img" :src="item.pictures" alt="">
                        <div class="hoteventsmore_content_title">{{item.activityName}}</div>
                        <div class="hoteventsmore_countdown_box">
                            <div>距离结束:</div>
                            <div v-if="item.day > 0" class="hoteventsmore_countdown_end">{{item.day}}天</div>
                            <div v-if="item.day > 0" style="color:#EF882C"> : </div>
                            <div v-if="item.hours > 0" class="hoteventsmore_countdown_end">{{item.hours}}时</div>
                            <div v-if="item.hours > 0" style="color:#EF882C"> : </div>
                            <div v-if="item.minutes > 0" class="hoteventsmore_countdown_end">{{item.minutes}}分</div>
                            <div v-if="item.minutes > 0" style="color:#EF882C"> : </div>
                            <div class="hoteventsmore_countdown_end">{{item.seconds}}秒</div>
                        </div>
                    </div>
                    <!-- 未开始倒计时 -->
                    <div v-if="!item.type">
                        <div class="hoteventsmore_label" style="background:#00B277">即将开始</div>
                        <img class="hoteventsmore_content_img" :src="item.pictures" alt="">
                        <div class="hoteventsmore_content_title">{{item.activityName}}</div>
                        <div class="hoteventsmore_countdown_box">
                            <div>距离开始:</div>
                            <div v-if="item.day > 0" class="hoteventsmore_countdown_end"
                                style="color:#00B277;border:#00B277 1px solid">{{item.day}}天</div>
                            <div v-if="item.day > 0" style="color:#00B277"> : </div>
                            <div v-if="item.hours > 0" class="hoteventsmore_countdown_end"
                                style="color:#00B277;border:#00B277 1px solid">{{item.hours}}时</div>
                            <div v-if="item.hours > 0" style="color:#00B277"> : </div>
                            <div v-if="item.minutes > 0" class="hoteventsmore_countdown_end"
                                style="color:#00B277;border:#00B277 1px solid">{{item.minutes}}分</div>
                            <div v-if="item.minutes > 0" style="color:#00B277"> : </div>
                            <div class="hoteventsmore_countdown_end" style="color:#00B277;border:#00B277 1px solid">
                                {{item.seconds}}秒</div>

                        </div>
                    </div>
                </div>
                <div class="hoteventsmore_contentbox_xian"></div>
            </div>
        </div>
        <!-- 导航 -->
        <!-- <div class="navber_bottom_box">
            <div class="navber_bottom_smallBox">
                <div @click="clickGoHome">
                    <SvgIcon class="navber_bottom_svg" iconClass="icon-shouyes"></SvgIcon>
                    <div style="color:rgb(45,178,122)">首页</div>
                </div>
            </div>
            <div class="navber_bottom_smallBox">
                <div @click="clickGoMy">
                    <SvgIcon class="navber_bottom_svg" iconClass="icon-woden"></SvgIcon>
                    <div>我的</div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { myActivitiesAPI } from '../../api/my'
export default {
    name: 'myhotevents',
    //我的活动
    components: {

    },
    data() {
        return {
            searchtype: false,
            inputSearchValue: "",
            hoteventsmoreContentList: [
                // {
                //     title: '"世界卫生日"热门活动',
                //     url: require('../../assets/home/hot.png'),
                //     type: true,
                //     day: 1,
                //     hours: 12,
                //     minutes: 50,
                //     seconds: 24,
                // },
            ],
            seTtimeouts: '',
        }
    },
    watch: {
    },
    created() {
        this.postindexActivitieslist()
    },
    methods: {
        focusinput() {
            //获取焦点
            this.searchtype = true
        },
        clickSearch() {
            // 取消
            this.inputSearchValue = ''
            this.searchtype = false
            this.postindexActivitieslist()
        },
        clickGoHome() {
            this.$router.push("/")
        },
        clickGoMy() {
            this.$router.push("/my")
        },
        postindexActivitieslist() {
            let data = {
                kv: { title: this.inputSearchValue, },
                page: 0,
                pageSize: 10,
            }
            myActivitiesAPI(data)
                .then(res => {
                    let list = res.data.data || []
                    list.map(v => {
                        if (v.state == 0) {//处理状态 
                            v.type = false
                            // 先计算每条数据的倒计时差值 0未开始
                            let starttimes = parseInt((new Date(v.startDateTime).getTime()) / 1000);
                            let newtimes = parseInt((new Date().getTime()) / 1000)
                            v.difftime = starttimes - newtimes
                        } else {
                            v.type = true
                            // 先计算每条数据的倒计时差值 1已开始
                            let starttimes = parseInt((new Date(v.endDateTime).getTime()) / 1000);
                            let newtimes = parseInt((new Date().getTime()) / 1000)
                            v.difftime = starttimes - newtimes
                        }
                    })
                    this.hoteventsmoreContentList = list
                    this.settimeoutfuntion()
                })
        },
        settimeoutfuntion() {
            // //视频未开始倒计时
            let endtime = 0
            this.hoteventsmoreContentList.map(v => {
                if (v.difftime > 0) {
                    v.day = parseInt(v.difftime / (60 * 60 * 24))
                    v.hours = parseInt(v.difftime / (60 * 60) % 24)
                    v.minutes = parseInt(v.difftime / 60 % 60)
                    v.seconds = parseInt(v.difftime % 60)
                    v.difftime = v.difftime - 1
                    this.$forceUpdate()
                } else {
                    endtime = 1
                }
            })
            if (this.seTtimeouts) clearTimeout(this.seTtimeouts)
            this.seTtimeouts = setTimeout(() => {
                if (endtime == 0) {
                    this.settimeoutfuntion()
                } else {
                    this.postindexActivitieslist()
                }
            }, 1000);

        },
        golink(e){
            if(this.hoteventsmoreContentList[e].link) window.location.href = this.hoteventsmoreContentList[e].link
        },
        gohotevents(e) {
            // 去
            let serviceID = this.hoteventsmoreContentList[e].serviceID
            this.$router.push(`/puzzle/${serviceID}`)
        },
    },
    beforeDestroy() {
        clearTimeout(this.seTtimeouts)
    },
   

}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.hoteventsmore_bigBox {
    width: 100%;
}

.hoteventsmore_box {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

}

.hoteventsmore_titlebox {
    width: @690px;
    margin-left: @30px;
    position: relative;
}

.hoteventsmore_content_Bigbox {
    width: 100%;
}

.hoteventsmore_content_box {
    margin-top: @10px;
    width: @690px;
    margin-left: @30px;
    position: relative;
}

.hoteventsmore_label {
    position: absolute;
    top: @40px;
    left: @20px;
    width: @110px;
    height: @44px;
    font-size: @24px;
    background: #EF882C;
    border-radius: @6px;
    color: #FFFFFF;
    text-align: center;
    line-height: @44px;
}

.hoteventsmore_content_img {
    width: @678px;
    height: @240px;
    border-radius: @20px;
    margin-top: @20px;
    object-fit: cover;
}

.hoteventsmore_content_title {
    margin-top: @20px;
    font-size: @34px;
}

.hoteventsmore_countdown_box {
    width: 100%;
    height: @40px;
    display: flex;
    align-items: center;
    font-size: @24px;
    margin-top: @10px;
}

.hoteventsmore_countdown_end {
    width: @70px;
    height: @36px;
    color: #EF882C;
    border: #EF882C 1px solid;
    border-radius: @2px;
    text-align: center;
    margin-left: @20px;
    margin-right: @20px;
}



.hoteventsmore_input_bigBox {
    margin-top: @30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: @20px;
}

.hoteventsmore_input_box {
    width: 100%;
    height: @70px;
    background: #F6F6F6;
    border: 1px solid #FFF;
    border-radius: @40px;
    /* border: 0 none; */
    /* outline: none; */
    padding-left: @60px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}

.hoteventsmore_input_svg {
    width: @30px;
    height: @30px;
    position: absolute;
    left: @20px;
    top: @24px;
}

.hoteventsmore_input_search {
    width: @70px;
    margin-left: @10px;
    /* display: flex;
    align-items: center; */
    text-align: center;
    line-height: @78px;
    font-size: @28px;
    color: #00B277;
}

.firstbox {
    background: -webkit-linear-gradient(top, rgba(239, 136, 44, 0.1), rgba(239, 136, 44, 0));
}

.navber_bottom_box {
    width: 100%;
    height: 7vh;
    display: flex;
    position: fixed;
    bottom: 0;
}

.navber_bottom_smallBox {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: @20px;
}

.navber_bottom_svg {
    width: @40px;
    height: @40px;
    margin-left: 50%;
    transform: translate(-50%);
}

.hoteventsmore_contentbox_xian {
    margin-top: @30px;
    width: 100%;
    height: @10px;
    background: #F8F8F8;
}
</style>
