<template>
  <svg class="icon-svg" :class="href" aria-hidden="true">  
    <use :xlink:href="`#${href}`"></use>  
  </svg>
</template>

<script>
  export default{
    props: {
      href: String
    }
  }
</script>
<style lang="less" scoped>
</style>
