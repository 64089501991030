// 配置微信
import { getWXapi } from '@/api/home'
import {md5} from 'vux'
import sha1 from 'sha1'
import VueCookie from 'vue-cookie'

export const wxSetting = () => {
  // 进度条
  let app = navigator.userAgent.toLowerCase()
  let url = window.location.href.split('#')[0]
  console.log(url)
  // 安卓设备隐藏进度条
  // if (app.includes('iphone')) {
  //   url = sessionStorage.getItem('firstPath')
  // }
  return new Promise((resolve, reject) => {
    let serviceCode = VueCookie.get('serviceCode')
    getWXapi(serviceCode).then((res) => {
      // console.log(res)
      let info = res.data.data
      const nonceStr = md5(new Date().getTime())
      const signature = sha1(`jsapi_ticket=${info.ticket}&noncestr=${nonceStr}&timestamp=${info.timestamp}&url=${url}`)
      // console.log(`jsapi_ticket=${info.ticket}&noncestr=${nonceStr}&timestamp=${info.timestamp}&url=${url}`)
      // console.log(signature)
      window.wx.config({
        debug: false, // 调试阶段建议开启
        appId: info.appId, // APPID
        timestamp: info.timestamp, // 时间戳timestamp
        nonceStr, // 随机数nonceStr
        signature, // 签名signature
        jsApiList: [ // JS接口列表
          'scanQRCode', // 二维码,
          'closeWindow', // 关闭窗口
          'previewImage', // 预览图片
          'chooseWXPay', // 微信支付
          'updateAppMessageShareData', // 分享好友
          'updateTimelineShareData', // 分享朋友圈
          'openLocation', // 读取位置
          'getLocation', // 获取位置
          'hideMenuItems', // 隐藏菜单
          'wx-open-launch-weapp' // 跳转小程序
        ],
        openTagList: ['wx-open-launch-weapp']
      })
      window.wx.ready(() => {
        // let btn = document.getElementById('launch-btn')
        // btn.addEventListener('launch', function (e) {
        //   console.log('success')
        // })
        window.wx.hideMenuItems({
          menuList: ['menuItem:share:qq', 'menuItem:share:weiboApp', 'menuItem:share:facebook', 'menuItem:share:QZone'] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
        })
        resolve()
      })
    })
  })
}

export const scanQRCode = (needResult = 0, success = () => {
}, fail = () => {
  this.$toast('请刷新页面再试！')
}) => {
  window.wx.scanQRCode({
    needResult, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
    success,
    fail
  })
}
