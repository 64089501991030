import { render, staticRenderFns } from "./biologymore.vue?vue&type=template&id=643492b0&scoped=true"
import script from "./biologymore.vue?vue&type=script&lang=js"
export * from "./biologymore.vue?vue&type=script&lang=js"
import style0 from "./biologymore.vue?vue&type=style&index=0&id=643492b0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "643492b0",
  null
  
)

export default component.exports