<template>
    <div class="Login_bigBox">
        <SvgIcon v-if="type == 1" class="Logo_img" iconClass="icon-logo2"></SvgIcon>
        <div v-if="type == 2" class="Logo_imgbox">
            <div class="Logo_imgbox_box"></div>
            <div>编辑个人资料</div>
        </div>
        <div class="input_bigBox" :class="{'inputbox':inputbottom[0].type}">
            <SvgIcon class="input_svg" iconClass="icon-xingming"></SvgIcon>
            <input @focus="inputfocus(0)" @blur="inputblur(0)" v-model="inputname" class="input_box" placeholder="输入名字"
                type="text">
        </div>
        <div class="input_bigBox" :class="{'inputbox':inputbottom[1].type}">
            <SvgIcon class="input_svg" iconClass="icon-suoshugongsiquancheng"></SvgIcon>
            <input @focus="inputfocus(1)" @blur="inputblur(1)" v-model="inputcompanyID" class="input_box maxinput"
                placeholder="输入所属企业（非必填）" type="text">
            <div @click="SelectEnterprisetype=true" class="select_enterprise">选择</div>
        </div>
        <div class="input_bigBox" :class="{'inputbox':inputbottom[2].type}">
            <SvgIcon class="input_svg" iconClass="icon-shoujihaoma"></SvgIcon>
            <input @focus="inputfocus(2)" @blur="inputblur(2)" v-model.number="inputphone" class="input_box"
                placeholder="输入手机号码" type="text">
        </div>
        <div class="input_bigBox" :class="{'inputbox':inputbottom[3].type}">
            <SvgIcon class="input_svg" iconClass="icon-yanzhengma"></SvgIcon>
            <input @focus="inputfocus(3)" @blur="inputblur(3)" v-model="inputverifyCode" class="input_box maxsinput"
                placeholder="输入验证码" type="text">
            <div v-if="!VerificationCodetype" @click="verifyCode" class="VerificationCode_Box">发送验证码</div>
            <div v-if="VerificationCodetype" class="VerificationCode_Boxs">{{VerificationCodeNumber}}</div>
        </div>
        <div @click="userregister" v-if="type == 1" class="login_Btn">
            注册
        </div>
        <div @click="userregister" v-if="type == 2" class="login_Btn">
            确定
        </div>
        <!-- 选择公司弹出框 -->
        <div v-if="SelectEnterprisetype" @click="SelectEnterprisetype=false" class="select_enterprise_bigBox">
            <div class="select_enterprise_box" @click.stop="">
                <div class="select_enterprise_title">
                    选择所属企业
                </div>
                <!-- <div class="enterprise_input_bigBox">
                    <input @change="postGroupName" v-model="inputGroupName" class="enterprise_input_box" type="text">
                    <SvgIcon class="enterprise_input_svg" iconClass="icon-sousuo-10"></SvgIcon>
                    <div class="enterprise_input_search">搜索</div>
                </div> -->
                <div class="select_enterprise_xian"></div>
                <div @click="clickGroupName(index)" class="select_groupName_box" v-for="(item,index) in GroupNameList"
                    :key="index">
                    <div>{{item.companyName}}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { userregisterAPI, verifyCodeAPI,getAuthUrlAPI,
    wxLogin, companylistAllAPI} from '../../api/log'
import {getuserstatusAPI} from '@/api/my'
import VueCookie from 'vue-cookie'
export default {
    name: 'login',
    data() {
        return {
            bigheight: '',
            inputbottom: [
                {
                    type: false
                },
                {
                    type: false
                },
                {
                    type: false
                },
                {
                    type: false
                },
            ],
            inputname: '',// 名字
            inputcompanyID: '',// 企业
            inputphone: '',// 手机
            inputverifyCode: '',// 短信
            type: 1,//1注册 2修改
            VerificationCodetype: false,//验证码倒计时
            VerificationCodeNumber: undefined,//验证码倒计时数字
            Times: undefined,//计时器
            SelectEnterprisetype: false,//选择公司弹出框
            GroupNameList: [

            ],
            inputGroupName: undefined,//搜索公司输入框
        }
    },
    created() {
        // this.$route.query  1注册 2修改
        // this.type = this.$route.query.type
        this.type = 2
        this.bigheight = document.body.scrollHeight + "px";
        this.showMyInfo()
        this.companylistAll()
    },
    methods: {
      // 回显信息
      showMyInfo() {
        getuserstatusAPI().then(res => {
          this.inputname = res.data.data.name
          this.inputphone = res.data.data.phone
          this.inputcompanyID = res.data.data.companyName
        }).catch(e => {
          console.log(e)
        })
      },
        verifyCode() {
            // 验证码
            if (!(/^1[3456789]\d{9}$/.test(this.inputphone))) {
                this.$toast('手机号格式错误')
                return
            }
            verifyCodeAPI(this.inputphone, 'bio')
                .then(res => {
                  this.$toast(res.data.msg)
                    if(res.data.msg == '成功'){
                        this.clickVerificationCode()
                    }
                })
        },
        clickVerificationCode() {
            //点击开始验证码倒计时
            this.VerificationCodetype = true
            this.VerificationCodeNumber = 60
            this.setTimeoutMethods()
        },
        setTimeoutMethods() {
            //验证码倒计时 计时器
            if(this.Times) clearTimeout(this.Times)
            this.Times = setTimeout(() => {
                if (this.VerificationCodeNumber > 0) {
                    this.VerificationCodeNumber--
                    this.setTimeoutMethods()
                } else {
                    clearTimeout(this.Times)
                    this.VerificationCodetype = false
                }
            }, 1000);
        },
        clickGroupName(index) {
            //点击获取公司名字
            this.inputcompanyID = this.GroupNameList[index].companyName
            this.SelectEnterprisetype = false
        },
        inputfocus(e) {
            this.inputbottom[e].type = true
        },
        inputblur(e) {
            this.inputbottom[e].type = false
        },
        // 点击注册
        userregister() {
          if(!this.inputname) {
            this.$toast('请输入名字')
            return
          } else if (!this.inputphone) {
            this.$toast('请输入手机号码')
            return
          } else if (!this.inputverifyCode) {
            this.$toast('请输入验证码')
            return
          }
            // 注册
            let data = {}
            data['name'] = this.inputname

            this.GroupNameList.map(v =>{
                if(this.inputcompanyID == v.companyName){
                    data['companyID'] = v.companyID
                }
            })
            data['phone'] = this.inputphone
            data['verifyCode'] = this.inputverifyCode
            data['serviceID'] = VueCookie.get("JSESSIONID")
            console.log(data);
            userregisterAPI(data).then(res => {
              if(res.data.code == 200){
                this.$toast(res.data.msg)
                setTimeout(_ => {
                  this.$router.back();
                }, 800)
              }
            })
            .catch(e => {
              console.log(e)
            })
        },
        // 微信授权
        getWechatCode() {
          let urls = window.location.href
          let data = {
            serviceCode: "bio",
            url: urls
          }
          getAuthUrlAPI(data).then(res => {
            console.log('重定向', res.data.data)
            VueCookie.delete('JSESSIONID');//重新请求code就要重新登录 清除cookies
            localStorage.setItem('firstLogins', 1)
            location.replace(res.data.data) // 重定向
          })
        },
        getUrlCode() {
            // 截取url中的code方法
            var urls = location.search;
            var theRequest = new Object();
            if (urls.indexOf("?") != -1) {
                var str = urls.substr(1);
                var strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
                }
            }
            return theRequest;
        },
        companylistAll(){
            companylistAllAPI()
            .then(res =>{
                let list = res.data.data || []
                this.GroupNameList = list
            })
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.Login_bigBox {
  width: 100%;
  min-height: 100vh;
  background-image: url("../../assets/login/loginbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

input {
    border: 0 none;
    outline: none;
}

.Logo_img {
    width: @200px;
    height: @182px;
    margin-top: @140px;
    margin-left: 50%;
    transform: translate(-50%);
}

.Logo_imgbox {

    height: @320px;
    font-size: @50px;
    margin-left: 50%;
    transform: translate(-50%);
    text-align: center;
    font-weight: bold;
    margin-bottom: @10px;
}

.Logo_imgbox_box {
    width: 100%;
    height: @169px;
    font-weight: bold;
}

.input_bigBox {
    width: @600px;
    height: @60px;
    margin-top: @60px;
    margin-left: @80px;
    border-bottom: @2px #F0F0F0 solid;
    display: flex;
    align-items: center;
    position: relative;
}

.inputbox {
    border-bottom: @2px #00B277 solid;

}

.input_svg {
    width: @36px;
    height: @36px;
    line-height: @60px;
}

.input_box {
    width: 100%;
    height: @60px;
    margin-left: @27px;
    background: rgba(0, 0, 0, 0);
    font-size: @32px;
}

input::-webkit-input-placeholder {
    /* 输入框提示文字颜色 */
    color: #AAAAAA;
}

.VerificationCode_Box {
    width: @160px;
    height: @54px;
    border: #CCCCCC @2px solid;
    border-radius: @2px;
    position: absolute;
    right: 0;
    top: -@10px;
    font-size: @26px;
    color: #666666;
    text-align: center;
    line-height: @54px;
}

.VerificationCode_Boxs {
    width: @160px;
    height: @54px;
    border: #00B277 @2px solid;
    border-radius: @2px;
    position: absolute;
    right: 0;
    top: -@10px;
    font-size: @26px;
    color: #00B277;
    text-align: center;
    line-height: @54px;
}

.login_Btn {
    width: @600px;
    height: @90px;
    margin-top: @120px;
    background: #00B277;
    margin-left: 50%;
    transform: translate(-50%);
    border-radius: @50px;
    font-size: @34px;
    color: #FFF;
    text-align: center;
    line-height: @90px;
}

.goRegsiter_Box {
    margin-top: @60px;
    text-align: center;
    font-size: @28px;
}

.select_enterprise {
    width: @80px;
    height: @45px;
    position: absolute;
    right: 0;
    font-size: @32px;
    color: #00B277;
}

.select_enterprise_bigBox {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
}

.select_enterprise_box {
    width: @600px;
    height: @800px;
    background: #FFF;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: @20px;
    overflow-y:auto;
}

.select_enterprise_title {
    width: 100%;
    text-align: center;
    margin-top: @20px;
    font-weight: bold;
    font-size: @35px;
}

.enterprise_input_bigBox {
    margin-top: @20px;
    width: 100%;

    display: flex;
    position: relative;
    /* background: #666666; */
}

.enterprise_input_box {
    width: @400px;
    height: @60px;
    border: 1px #AAAAAA solid;
    border-radius: @40px;
    /* border: 0 none; */
    /* outline: none; */
    margin-left: @50px;
    padding-left: @60px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}

.enterprise_input_svg {
    width: @36px;
    height: @36px;
    position: absolute;
    left: @60px;
    top: @16px;
}

/* .enterprise_input_svg:focus{
    outline: none;
    border: 1px solid #00ccff;
} */
.enterprise_input_search {
    margin-left: @10px;
    display: flex;
    align-items: center;
    font-size: @28px;
}

.select_enterprise_xian {
    width: 100%;
    height: @20px;
    margin-top: @20px;
    background-color: rgb(243, 243, 243);
}

.select_groupName_box {
    padding: @20px @40px;
    border-top: 1px solid rgb(243, 243, 243);
    font-weight: 500;
    font-size: @34px;
}

.maxinput{
    width: 70%;
}
.maxsinput{
    width: 50%;

}
</style>
