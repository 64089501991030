import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/login.vue'
import regsiter from '../views/login/regsiter.vue'
import home from '../views/home/home.vue'
import my from '../views/my/my.vue'
import myactivity from '../views/my/myactivity.vue'
import myhotevents from '../views/my/myhotevents.vue'
import earlynewsbusmore from '../views/more/earlynewsbusmore.vue'
import hoteventsmore from '../views/more/hoteventsmore.vue'
import biologymore from '../views/more/biologymore.vue'
import headlinemore from '../views/more/headlinemore.vue'
import puzzle from '../views/puzzle/activity.vue'
import BIOmore from '../views/more/BIOmore.vue'
import livevideomore from '../views/more/livevideomore.vue'
import article from '../views/article/article.vue'
import service from '../views/personnel/service.vue'
import servicemore from '../views/personnel/servicemore.vue'
import serviceapartment from '../views/personnel/serviceapartment.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta:{title: '首页'},
    component: home,
  },
  {
    path: '/regsiter',
    name: 'regsiter',
    meta:{title: '编辑/注册'},
    component: regsiter
  },
  {
    path: '/login',
    name: 'login',
    meta:{title: '登录'},
    component: login
  },
  {
    path: '/my',
    name: 'my',
    meta:{title: '我的'},
    component: my,
  },
  {
    path: '/resume/index',
    name: 'index',
    meta: { title: '我的简历' },
    component: () => import('@/views/resume/index')
  },
  {
    path: '/resume/apply/:type',
    name: 'apply',
    meta: { title: '编辑简历' },
    component: () => import('@/views/resume/apply')
  },
  {
    path: '/myactivity',
    name: 'myactivity',
    meta:{title: '我的奖品'},
    component: myactivity,
  },
  {
    path: '/earlynewsbusmore',
    name: 'earlynewsbusmore',
    meta:{title: '新闻早班车'},
    component: earlynewsbusmore,
  },
  {
    path: '/hoteventsmore',
    name: 'hoteventsmore',
    meta:{title: '热门活动'},
    component: hoteventsmore,
  },
  {
    path: '/biologymore',
    name: 'biologymore',
    meta:{title: '前沿观察'},
    component: biologymore,
  },
  {
    path: '/headlinemore',
    name: 'headlinemore',
    meta:{title: '资讯头条'},
    component: headlinemore,
  },
  {
    path: '/puzzle/:id',
    component: () => import('@/views/puzzle/activity'),
    meta: {
      title: '活动详情'
    }
  },
  {
    path: '/puzzle/answer/:id',
    component: () => import('@/views/puzzle/answer'),
    meta: {
      title: '答题'
    }
  },
  {
    path: '/myPuzzlePrize',
    component: () => import('@/views/puzzle/myPrize'),
    meta: {
      title: '我的奖品'
    }
  },
  {
    path: '/BIOmore',
    name: 'BIOmore',
    meta:{title: '配套服务'},
    component: BIOmore,
  },
  {
    path: '/livevideomore',
    name: 'livevideomore',
    meta:{title: '直播详情'},
    component: livevideomore,
  },
  {
    path: '/myhotevents',
    name: 'myhotevents',
    meta:{title: '我的活动'},
    component: myhotevents,
  },
  {
    path: '/article',
    name: 'article',
    meta:{title: '文章详情'},
    component: article,
  },
  {
    path: '/service',
    name: 'service',
    meta:{title: '人才服务'},
    component: service,
  },
  {
    path: '/servicemore',
    name: 'servicemore',
    meta:{title: '人才服务'},
    component: servicemore,
  },
  {
    path: '/serviceapartment',
    name: 'serviceapartment',
    meta:{title: '人才公寓'},
    component: serviceapartment,
  },
  {
    path: '/recruitmore',
    component: () => import('@/views/personnel/recruitmore'),
    meta: {
      title: '招聘详情'
    }
  },
  {
    path: '/pestilenceService',
    component: () => import('@/views/Pestilence/service'),
    meta: {
      title: '防疫服务'
    }
  },
  {
    path: '/pestilenceNotice',
    component: () => import('@/views/Pestilence/notice'),
    meta: {
      title: '防疫通知'
    }
  },
  {
    path: '/pestilenceAddress',
    component: () => import('@/views/Pestilence/address'),
    meta: {
      title: '核酸检测点'
    }
  },
  {
    path: '/pestilencePolicy',
    component: () => import('@/views/Pestilence/policy'),
    meta: {
      title: '防疫政策'
    }
  },
  {
    path: '/myDelivery',
    component: () => import('@/views/my/myDelivery'),
    meta: {
      title: '我的投递'
    }
  },
  {
    path: '/signIn',
    component: () => import('@/views/signIn/index'),
    meta: {
      title: '签到打卡'
    }
  },
  {
    path: '/exchange',
    component: () => import('@/views/signIn/exchange'),
    meta: {
      title: '兑换礼品'
    }
  },
  {
    path: '/scanCode',
    component: () => import('@/views/signIn/scanCode'),
    meta: {
      title: '使用礼品'
    }
  },
  {
    path: '/signGift',
    component: () => import('@/views/signIn/signGift'),
    meta: {
      title: '我的兑换'
    }
  },
  {
    path: '/supporting',
    component: () => import('@/views/more/supporting'),
    meta: {
      title: '配套服务'
    }
  },
  {
    path: '/parkImpression',
    component: () => import('@/views/more/parkImpression'),
    meta: {
      title: '园区印象'
    }
  },
  {
    path: '/vote/myVote',
    component: () => import('@/views/vote/myVote/myList'),
    meta: {
      title: '我报名的投票'
    }
  },
  {
    path: '/vote/entry/apply/:id',
    component: () => import('@/views/vote/myVote/apply'),
    meta: {
      title: '报名'
    }
  },
  {
    path: '/vote/group/itemDetail/:id',
    component: () => import('@/views/vote/pollGroup/itemDetail'),
    meta: {
      title: '参与'
    }
  },
  {
    path: '/lottery/:id',
    component: () => import('@/views/lottery/index'),
    meta: {
      title: '积分抽奖'
    }
  },
  {
    path: '/lottery/bingoList/:id',
    component: () => import('@/views/lottery/prize'),
    meta: {
      title: '中奖名单'
    }
  },
  {
    path: '/lotteryRule',
    component: () => import('@/views/lottery/rule'),
    meta: {
      title: '积分抽奖活动规则'
    }
  },
  {
    path: '/lotteryMyPrize',
    name: 'fwhDrawMine',
    component: () => import('@/views/lottery/myPrize'),
    meta: {
      title: '我的奖品'
    }
  },
  {
    path: '/midAutumn/:id',
    component: () => import('@/views/midAutumn/index'),
    meta: {
      title: 'BIO一起闯'
    }
  },
  {
    path: '/midAutumnAnswer',
    component: () => import('@/views/midAutumn/paper'),
    meta: {
      title: '活动答题'
    }
  },
  {
    path: '/cardCollecting/:id',
    component: () => import('@/views/card/index'),
    meta: {
      title: '活动详情'
    }
  },
  {
    path: '/vote/group',
    component: () => import('@/views/vote/pollGroup/toolBar'),
    meta: {
      title: '投票评选'
    },
    children: [
      // 投票项
      {
        path: 'item/:id',
        name: 'voteGroup',
        component: () =>
        import('@/views/vote/pollGroup/group'),
        meta: {
          title: '投票',
          keepAlive: true,
          refresh: true
        }
      },
      // 榜单
      {
        path: 'rank/:id',
        component: () =>
        import('@/views/vote/pollGroup/rank'),
        meta: {
          title: '榜单',
          keepAlive: true
        }
      },
      // 介绍
      {
        path: 'intro/:id',
        component: () =>
        import('@/views/vote/pollGroup/intro'),
        meta: {
          title: '介绍',
          keepAlive: true
        }
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
