<template>
  <div class="my_bigbox">
    <img class="my_box_bigimg" src="../../assets/home/我的bg.png" alt="">
    <div class="my_box">
      <div class="my_top_bigbox">
        <div class="my_top_editbox" @click="clickEdit">编辑资料</div>
        <img class="my_top_face" :src="userinfo.avatar" alt="">
        <div class="my_top_namebox">
          <div class="my_top_name">{{userinfo.name}}</div>

          <div class="my_top_integralbox" @click="toSign">
            <img class="my_top_integral" src="../../assets/home/积分.png" alt="">
            <div class="my_top_integralnumber">{{userinfo.score || 0}}</div>
          </div>
        </div>
        <div class="my_top_company">{{userinfo.companyName}}</div>
      </div>
      <div class="my_content_bigbox">
        <div @click="gocontent(item.path)" class="my_content_box" v-for="(item,index) in contentList" :key="index">
          <img class="my_content_boximg" :src="item.url" alt="">
          <div class="my_content_boxdiv">{{item.title}}</div>
        </div>
      </div>
    </div>
    <!-- 敬请期待 弹窗 -->
    <div v-if="expecttype" class="my_expectbigbox" @click="closeExpect">
      <div class="my_expectbox" @click.stop="">
        <div @click="closeExpect">
          <SvgIcon class="my_svg" iconClass="icon-guanbi"></SvgIcon>
        </div>
        <img src="../../assets/home/空.png" alt="">
        <div class="my_text">敬请期待</div>
        <div class="my_texts">内容正在建设中...</div>
      </div>
    </div>
    <!-- 导航 -->
    <div class="navber_bottom_box">
      <div class="navber_bottom_smallBox">
        <div @click="clickGoHome">
          <SvgIcon class="navber_bottom_svg" iconClass="icon-shouyen"></SvgIcon>
          <div>首页</div>
        </div>
      </div>
      <div class="navber_bottom_smallBox">
        <div @click="clickGoMy">
          <SvgIcon class="navber_bottom_svg" iconClass="icon-wodes"></SvgIcon>
          <div style="color:rgb(45,178,122)">我的</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getuserstatusAPI } from '../../api/my'

export default {
  name: "my",
  // 我的
  components: {
  },
  data() {
    return {
      expecttype: false,//敬请期待 弹窗
      userinfo: {
        url: require("../../assets/home/人才服务.png"),
        name: "",
        integral: 666,
        company: "成都智普测医疗有限公司成都智普测医疗有限公司",
      },
      contentList: [
        {
          title: "我的活动",
          url: require('../../assets/home/我的活动.png'),
          path: '/myhotevents'
        },
        {
          title: "我的奖品",
          url: require('../../assets/home/我的奖品.png'),
          path: '/myactivity'
        },
        {
          title: "我的兑换",
          url: require('../../assets/home/我的兑换.png'),
          path: '/signGift'
        },
        {
          title: "我的简历",
          url: require('../../assets/home/我的简历.png'),
          path: '/resume/index'
        },
        {
          title: "我的投递",
          url: require('../../assets/home/我的投递.png'),
          path: '/myDelivery'
        },
        {
          title: "我的投票",
          url: require('../../assets/home/vote.png'),
          path: '/vote/myVote'
        },
      ]
    }
  },
  created() {
    this.getuserstatus()
  },
  methods: {
    clickGoHome() {
      this.$router.push('/')
    },
    clickGoMy() {
      this.$router.go(0)
    },
    gocontent(path) {
      if(path) {
        this.$router.push(path)
      } else {
        this.expecttype = true
      }
    },
    closeExpect() {
      this.expecttype = false
    },
    clickEdit() {
      this.$router.push({
        name: 'regsiter',
        query: {
          type: 2
        }
      })

    },
    getuserstatus() {
      getuserstatusAPI()
        .then(res => {
          console.log(res);
          let list = res.data.data
          if (!list.name) {
            list.name = list.nickname
          }
          if (!list.avatar) {
            list.avatar = require("../../assets/home/人才服务.png")
          }
          this.userinfo = list
        })
    },
    // 签到
    toSign() {
      // this.$router.push('/signIn')
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.my_bigbox {
  width: 100%;
  position: relative;
}

.my_box_bigimg {
  width: @700px;
  height: @326px;
  position: absolute;
  top: 0;
  z-index: 0;
}

.my_box {
  width: 100%;
  height: 93vh;
  z-index: 1;
  position: absolute;
  top: 0;
}

.my_top_bigbox {
  width: 80%;
  margin-top: @40px;
  margin-left: 10%;
  position: relative;
}

.my_top_editbox {
  width: @160px;
  height: @60px;
  position: absolute;
  top: 0;
  right: 0;
  background: #00B277;
  color: #FFF;
  text-align: center;
  line-height: @60px;
  border-radius: @46px;
  font-size: @26px;
}

.my_top_face {
  width: @130px;
  height: @130px;
  border-radius: 50%;
}

.my_top_namebox {
  width: 100%;
  height: @62px;
  margin-top: @10px;
  display: flex;
  justify-content: space-between;
}

.my_top_name {
  width: @400px;
  height: 100%;
  font-size: @44px;
  font-weight: Bold;
  overflow: hidden;
  /*溢出的部分隐藏*/
  white-space: nowrap;
  /*文本不换行*/
  text-overflow: ellipsis;
  /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}

.my_top_integralbox {
  width: @140px;
  height: @62px;
  display: flex;
  align-items: center;
}

.my_top_integral {
  width: @50px;
  height: @50px;
}

.my_top_integralnumber {
  font-size: @50px;
  color: #EF882C;
  margin-left: @6px;

}

.my_top_company {
  width: 100%;
  height: @37px;
  font-size: @26px;
  color: #444444;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: @10px;
}

.my_content_bigbox {
  margin-left: @30px;
  margin-top: @20px;
  width: @720px;
  display: flex;
  flex-wrap: wrap;
}

.my_content_box {
  width: @210px;
  height: @210px;
  position: relative;
  margin-top: @51px;
  margin-right: @30px;
}

.my_content_boximg {
  width: @210px;
  height: @210px;
}

.my_content_boxdiv {
  // width: @104px;
  height: @37px;
  font-size: @26px;
  position: absolute;
  top: @141px;
  left: @53px;
}

.my_expectbigbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.my_expectbox {
  width: @640px;
  height: @430px;
  background: #FFFFFF;
  border-radius: @20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.my_svg {
  width: @30px;
  height: @30px;
  position: absolute;
  top: @30px;
  right: @30px;
}

.my_expectbox>img {
  margin-top: @50px;
  width: @300px;
  height: @200px;
  margin-left: 50%;
  transform: translate(-50%);
}

.my_text {
  margin-top: @30px;
  width: 100%;
  font-size: @36px;
  text-align: center;
  color: #2878FF
}

.my_texts {
  margin-top: @10px;
  width: 100%;
  font-size: @30px;
  text-align: center;
  color: #666666;
}

.navber_bottom_box {
  width: 100%;
  height: 7vh;
  display: flex;
  position: fixed;
  bottom: 0;
  background: #FFF;
  z-index: 2;
}

.navber_bottom_smallBox {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: @20px;
}

.navber_bottom_svg {
  width: @40px;
  height: @40px;
  margin-left: 50%;
  transform: translate(-50%);
}
</style>
