import VueCookie from 'vue-cookie'
// 系统变量配置
let JSESSIONID = ''
// ================================= 开发环境 =========================================
// JSESSIONID = '8f240d90f9f443dfbbd04c1a23f8c490' // 会员｜测试
// ================================= 测试环境 =========================================
JSESSIONID = '32467b4873d543139e2e4d6cbea33106' // 农民｜农名N（Nitity）

// ================================= 生产环境 =========================================
/**
 * 生产环境
 * 根据不同工会设置默认登录用户
 */
let serviceCode = VueCookie.get('serviceCode')

if (process.env['NODE_ENV'] === 'production') {
  let list = {
    'bio': '32467b4873d543139e2e4d6cbea33106'
  }
  if (list[serviceCode]) {
    JSESSIONID = list[serviceCode]
  } else {
    JSESSIONID = '32467b4873d543139e2e4d6cbea33106'
  }
}
if (process.env['NODE_ENV'] === 'test') {
  let list = {
    'bio': '32467b4873d543139e2e4d6cbea33106'
  }
  if (list[serviceCode]) {
    JSESSIONID = list[serviceCode]
  } else {
    JSESSIONID = '32467b4873d543139e2e4d6cbea33106'
  }
}
let isWxBrowser = true // 检测当前运行环境是否微信浏览器
let ua = navigator.userAgent
if (!ua.includes('MicroMessenger')) {
  isWxBrowser = false
}
const config = {
  development: {
    env: 'dev',
    api: 'http://192.168.1.248:8080',
    codeUrl: `/wxh5/common/verifyCode?debug=${JSESSIONID}&random=`,
    JSESSIONID,
    uploadHost: 'http://test.file.bio.ichuangye.cn',
    fileHostName: 'http://test.file.bio.ichuangye.cn',
    bucket: 'ichuangye-1258552007', // 腾讯云对象储存桶名
    region: 'ap-chengdu', // 对象储存你所处的地区编号，这里是广州
    // uploadHost: 'https://file.shikra.ichuangye.cn',
    isH5embed: false, // 是否需要开启内嵌页面转换,(本地调试不需要)
    isWxBrowser,
    MMGTURL: 'http://192.168.1.248:8023/login'
  },
  test: {
    env: 'test',
    api: 'http://open.xiahe.me',
    codeUrl: '/wxh5/common/verifyCode?random=',
    uploadHost: 'http://test.file.bio.ichuangye.cn',
    fileHostName: 'http://test.file.bio.ichuangye.cn',
    bucket: 'ichuangye-1258552007', // 腾讯云对象储存桶名
    region: 'ap-chengdu', // 对象储存你所处的地区编号，这里是广州
    JSESSIONID,
    isH5embed: true,
    isWxBrowser,
    MMGTURL: 'https://qyh5.shikra.ichuangye.cn/index?page=e98a17be'
  },
  production: {
    env: 'prod',
    api: 'http://open.xiahe.me',
    codeUrl: '/wxh5/common/verifyCode?random=',
    uploadHost: 'https://file.bio.ichuangye.cn',
    fileHostName: 'https://file.bio.ichuangye.cn',
    bucket: 'nc-1258552007', // 腾讯云对象储存桶名
    region: 'ap-chengdu', // 对象储存你所处的地区编号，这里是广州
    JSESSIONID: JSESSIONID,  // 固定生产环境川工之家入口用户 正确
    isH5embed: true,
    isWxBrowser,
    MMGTURL: 'https://mmgt.cheetah.ichuangye.cn/index'
  }
}
export default config[process.env['NODE_ENV']]
