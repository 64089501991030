import { render, staticRenderFns } from "./normalIndex.vue?vue&type=template&id=5b0f0779&scoped=true"
import script from "./normalIndex.vue?vue&type=script&lang=js"
export * from "./normalIndex.vue?vue&type=script&lang=js"
import style0 from "./normalIndex.vue?vue&type=style&index=0&id=5b0f0779&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0f0779",
  null
  
)

export default component.exports