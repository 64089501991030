<template>
  <div class="myactivity_bigbox">
    <van-tabs swipe-threshold="1"  @click="clicktab" class="myactivity_tab_box" v-model="active">
      <van-tab title="全部"></van-tab>
      <van-tab title="红包"></van-tab>
      <van-tab title="实物"></van-tab>
      <!-- <van-tab title="积分"></van-tab>
      <van-tab title="优惠价"></van-tab> -->
    </van-tabs>
    <div v-for="(item,index) in contentlist" :key="index" class="myactivity_content_bigbox">
      <div class="myactivity_content_box">
        <img :src="item.url" alt="">
        <div class="myactivity_content_smallbox">
          <div class="content_smallbox_title">
            {{item.moduleName}}
          </div>
          <div class="content_smallbox_times">
            中奖时间: {{item.createTime}}
          </div>
          <!-- <div v-if="item.exchange == 2" class="content_smallbox_times">
            兑换时间: {{item.endtime}}
          </div>
          <div v-if="item.exchange == 3" class="content_smallbox_times">
            过期时间: {{item.endtime}}
          </div> -->
        </div>
        <!-- 未兑换 -->
        <div class="myactivity_content_rightbox">
          <!-- 4种奖品  -->
          <div  class="content_rightbox_title">系统发放</div>
          <!-- <div v-if="item.type == 2" class="content_rightbox_title">统一邮寄</div>
          <div v-if="item.type == 3" class="content_rightbox_title">系统发放</div>
          <div v-if="item.type == 4" class="content_rightbox_title">扫码兑换</div> -->
          <!-- 三种情况 1未兑换 2已兑换 3已过期 -->
          <!-- <div v-if="item.exchange == 1" class="content_rightbox_titlebox" style="background:#FF6600">去兑换</div>
          <div v-if="item.exchange == 2" class="content_rightbox_titlebox">已兑换</div>
          <div v-if="item.exchange == 3" class="content_rightbox_titlebox" style="background:#AAAAAA">已过期</div> -->
        </div>
      </div>
      <div class="myactivity_content_xian"></div>
    </div>
  </div>
</template>

<script>
  import { useLoglistAPI } from '../../api/my'
export default {
  name: 'myactivity',
  //我的礼物
  components: {

  },
  data() {
    return {
      active: 0,//tab标签页
      contentlist: [],
      contentlists: [
        {
          url: require('../../assets/home/hongbao.png'),
          title: '10元红包',
          starttime: '2022-06-07 10:10',
          endtime: '2022-06-07 10:10',
          type: 1, // 1红包 2实物 3积分 4优惠价
          exchange: 1,//1未领 2领取 3过期
        },
        {
          url: require('../../assets/home/hongbao.png'),
          title: '10元红包',
          starttime: '2022-06-07 10:10',
          endtime: '2022-06-07 10:10',
          type: 1,
          exchange: 2,
        },
        // {
        //   url: require('../../assets/home/BIO6.png'),
        //   title: '蓝牙音响蓝牙音响蓝牙音响',
        //   starttime: '2022-06-07 10:10',
        //   endtime: '2022-06-07 10:10',
        //   type: 2, // 1红包 2实物 3积分 4优惠价
        //   exchange: 1,//1未领 2领取 3过期
        // },
        // {
        //   url: require('../../assets/home/BIO6.png'),
        //   title: '蓝牙音响蓝牙音响蓝牙音响',
        //   starttime: '2022-06-07 10:10',
        //   endtime: '2022-06-07 10:10',
        //   type: 2,
        //   exchange: 2,
        // },
        // {
        //   url: require('../../assets/home/BIO6.png'),
        //   title: '蓝牙音响蓝牙音响蓝牙音响',
        //   starttime: '2022-06-07 10:10',
        //   endtime: '2022-06-07 10:10',
        //   type: 2,
        //   exchange: 3,
        // },
        // {
        //   url: require('../../assets/home/BIO3.png'),
        //   title: '10积分',
        //   starttime: '2022-06-07 10:10',
        //   endtime: '2022-06-07 10:10',
        //   type: 3, // 1红包 2实物 3积分 4优惠价
        //   exchange: 1,//1未领 2领取 3过期
        // },
        // {
        //   url: require('../../assets/home/BIO3.png'),
        //   title: '10积分',
        //   starttime: '2022-06-07 10:10',
        //   endtime: '2022-06-07 10:10',
        //   type: 3,
        //   exchange: 2,
        // },
        // {
        //   url: require('../../assets/home/BIO2.png'),
        //   title: '罗森10元优惠劵',
        //   starttime: '2022-06-07 10:10',
        //   endtime: '2022-06-07 10:10',
        //   type: 4, // 1红包 2实物 3积分 4优惠价
        //   exchange: 1,//1未领 2领取 3过期
        // },
        // {
        //   url: require('../../assets/home/BIO2.png'),
        //   title: '罗森10元优惠劵',
        //   starttime: '2022-06-07 10:10',
        //   endtime: '2022-06-07 10:10',
        //   type: 4,
        //   exchange: 2,
        // },
        // {
        //   url: require('../../assets/home/BIO2.png'),
        //   title: '罗森10元优惠劵',
        //   starttime: '2022-06-07 10:10',
        //   endtime: '2022-06-07 10:10',
        //   type: 4,
        //   exchange: 3,
        // }
      ],
    }
  },
  created() {
    this.useLoglist()
  },
  methods: {
    clicktab(e) {
      this.contentlist = []
      if (e < 2) {
        this.useLoglist()
      }else{
    
      }
    },
    useLoglist(){
      let data = {
        kv : {},
        page : 0,
        pageSize : 10
      }
      useLoglistAPI(data)
      .then(res =>{
        let list = res.data.data.list || []
        list.map(v =>{
          v.url = require('../../assets/home/hongbao.png')
        })
        this.contentlist = list
      })
    }
  }


}
</script>

<style lang="less" scoped>
  @import "../../assets/css/pixel/pixel";
.myactivity_bigbox {
  width: 100%;
}

.myactivity_tab_box {
  width: 80%;
  margin-left: 10%;
  margin-top: @20px;
}

/deep/ .van-tabs__line {
  background: #EF882C;
}

/deep/.van-tab--active {
  font-weight: bold;
  font-size: @34px;
}

.myactivity_content_bigbox {
  width: 100%;
}

.myactivity_content_box {
  width: 100%;
  margin-top: @30px;
  display: flex;
  align-items: center;
}

.myactivity_content_box>img {
  width: @180px;
  height: @135px;
  margin-left: @30px;

}

.myactivity_content_smallbox {
  width: @368px;
  height: 100%;
  margin-left: @20px;

}

.content_smallbox_title {
  width: @360px;
  height: @48px;
  font-size: @34px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.content_smallbox_times {
  font-size: @24px;
  color: #777777;
  margin-top: @6px;
}

.myactivity_content_rightbox {
  width: @120px;
  height: 100%;
}

.content_rightbox_title {
  font-size: @26px;
  color: #EF882C;
  text-align: center;
}

.content_rightbox_titlebox {
  width: @120px;
  height: @50px;
  background: rgba(255, 153, 0, 0.5);
  border-radius: @26px;
  color: #fff;
  text-align: center;
  line-height: @50px;
  margin-top: @10px;
}

.myactivity_content_xian {
  margin-top: @30px;
  width: 100%;
  height: @10px;
  background: rgba(248, 248, 248, 1);
}
</style>
