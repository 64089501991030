<template>
    <div class="bigbox">
      <van-sticky>
        <div class="head_bigbox">
          <input @keyup.enter="clickSearch" @focus="focusinput" v-model="inputSearchValue" class="input_box"
                 placeholder="输入标题搜索" type="search">
          <div>
            <SvgIcon class="input_svg" iconClass="icon-sousuo-10"></SvgIcon>
          </div>
          <div v-if="inputSearchValue != ''" @click="clearSearch" class="input_X">
            <svg t="1665641559757" class="icon" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="22455" width="16" height="16">
              <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#AAAAAA" p-id="22456">
              </path>
              <path
                d="M583.68 512l136.533333-136.533333c20.48-20.48 20.48-51.2 0-71.68s-51.2-20.48-71.68 0l-136.533333 136.533333-136.533333-136.533333c-20.48-20.48-51.2-20.48-71.68 0s-20.48 51.2 0 71.68l136.533333 136.533333-136.533333 136.533333c-20.48 20.48-20.48 51.2 0 71.68s51.2 20.48 71.68 0l136.533333-136.533333 136.533333 136.533333c20.48 20.48 51.2 20.48 71.68 0s20.48-51.2 0-71.68l-136.533333-136.533333z"
                fill="#FFFFFF" p-id="22457"></path>
            </svg>
          </div>
          <div v-if="searchtype" @click="clickSearch" class="input_search">搜索</div>
        </div>
      </van-sticky>

      <div class="xian"></div>
      <div class="content_bigbox">
        <m-scroll ref='mscroll' v-model="pages" :getData="postTalentsApartmentlist" emptyText="暂无数据">
          <div @click="golink(index)" class="content_box" v-for="(item,index) in contentList" :key="index">
            <div class="content_textbox">
              {{item.title}}
            </div>
            <div class="content_textbottom">
              <div class="content_texttype" v-if="item.createTime">{{item.createTime.substr(0, 16)}}</div>
            </div>
          </div>
        </m-scroll>
      </div>
    </div>
</template>

<script>
import { getTalentsApartment, postTalentsApartmentlist } from '../../api/personnel'
import {postCommonAddClick} from "@/api/home";
export default {
    name: 'serviceapartment',
    // 人才公寓
    components: {

    },
    data() {
        return {
          contentList: [],//列表
          inputSearchValue: '',//搜索框
          searchtype: false,
          pages: {
            page: 1,
            pageSize: 10,
            kv: {
              title: ''
            }
          }
        }
    },
    created() {
        this.postTalentsApartmentlist()
    },
    methods: {
        focusinput() {
          //获取焦点
          this.searchtype = true
        },
        clearSearch() {
          // 取消
          this.inputSearchValue = ''
          this.searchtype = false
          this.pages.page = 1
          this.postTalentsApartmentlist()
        },
        clickSearch() {
          this.pages.page = 1
          this.postTalentsApartmentlist()
        },
        postTalentsApartmentlist() {
          this.pages.kv.title = this.inputSearchValue
          //列表
          if (this.pages.page === 1) this.contentList = []
          postTalentsApartmentlist(this.pages).then(res => {
            let arr = res.data.data
            this.contentList = [...this.contentList, ...arr.list]
            this.$refs.mscroll.endSuccess(arr)
          })
        },
        golink(index) {
          this.addViews(this.contentList[index].talentsApartmentID, 'talentsApartment')
            if (this.contentList[index].source == 2) {
                this.$router.push({
                    name: 'article',
                    query: {
                        serviceID: this.contentList[index].serviceID,
                        from: "人才公寓"
                    }
                })
                return
            }

            if (this.contentList[index].type == 1) {
                this.$emit("videoUrl", this.contentList[index].videos.url)
            } else {
                if (this.contentList[index].link) window.location.href = this.contentList[index].link
            }
        },
      //  增加阅读量
      addViews(id, key) {
        postCommonAddClick(key, id)
      }
    }

}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.head_bigbox {
  padding: @30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
}

.input_box {
    width: 100%;
    height: @70px;
    background: #F6F6F6;
    border: 1px solid #FFF;
    font-size: @30px;
    border-radius: @40px;
    /* border: 0 none; */
    /* outline: none; */
    padding-left: @60px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}

.input_svg {
    width: @30px;
    height: @30px;
    position: absolute;
    left: @50px;
    top: @50px;
}

.input_X {
    width: @20px;
    height: @30px;
    position: absolute;
    right: @145px;
    top: @50px;

}


.input_search {
    width: @70px;
    margin-left: @10px;
    /* display: flex;
    align-items: center; */
    text-align: center;
    line-height: @70px;
    font-size: @28px;
    color: #00B277;
}

.xian {
    width: 100%;
    height: @10px;
    background: #F8F8F8;

}

.content_bigbox {
    width: 100%;

  .mscroll{
    background-color: #fff;
  }
}

.content_title {
    margin-top: @30px;
    margin-left: @30px;
    font-size: @34px;
    font-weight: bold;
}

.content_box {
    width: @690px;
    margin-top: @20px;
    margin-left: @30px;
    background: #FAFAFA;
    padding: @30px;
    border-radius: @10px;
}

.content_textbox {
    width: 100%;
    font-size: @34px;
    line-height: @48px;


}

.content_textbottom {
    width: 100%;
    height: @40px;
    margin-top: @10px;
    // background: #EF882C;
    font-size: @28px;
    display: flex;
    align-items: center;
}

.content_svg {
    width: @30px;
    height: @30px;
}

.content_texttype {
    color: #666666;
    line-height: @40px;
    font-size: @26px;
}

.content_bottombox {
    width: 100%;
    height: @150px;
    color: #2878FF;
    font-size: @28px;
    text-align: center;
    margin-top: @30px;
}
</style>
